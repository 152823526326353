import React from "react"
import PageHeader from "components/PageHeader"
import Seo from "components/Seo"
import SectionPrograms from "components/SectionPrograms"
import SectionWhyUs from "components/SectionWhyUs"
import SectionContactCta from "components/SectionContactCta"

const CASES = [
  {
    title: "작업조직 설계 및 운영",
    description:
      "제품생산을 위한 공정/설비/작업자 배치와 관련된 모든 계획과 설계, 운영을 의미하는 것으로, 각 설비 또는 공정 간에 제품 운반과 취급의 최소화를 위해 설비/공정/작업자를 적절히 배치하여 생산의 흐름이 최적화되도록 설계합니다.",
  },
  {
    title: "작업표준화",
    description:
      "작업의 최적방법을 위한 작업표준서를 작성하는 것으로, 공정에서 품질을 보증하기 위한 품질검사체계 설계 및 운영과 정기적으로 표준의 현장 준수에 대한 평가, 작업개선을 반영하여 표준 작성합니다.",
  },
  {
    title: "작업자 역량개발",
    description:
      "작업자의 숙련 강화 및 다기능화를 위한  skill 관리 프로세스 및 skill 평가 방법 정립, 작업자 skill 교육 및 훈련, 작업자 문제해결 역량 강화, 다기능/숙련의 보상체계와 연계합니다.",
  },
  {
    title: "작업장 환경개선",
    description:
      "생산활동에서 기본과 원칙의 준수 및 안전하고 쾌적한 환경을 만들기 위한 활동으로, 3정 5S, 위해위험요소 개선활동 등이 현장 주도로 체계적으로 이루어질 수 있도록 함. 근로자 참여를 통한 작업장 내 개선문화 구축합니다.",
  },
]

export default function ManufacturingProgramPage() {
  return (
    <div>
      <Seo
        title={"제조혁신"}
        description="제조혁신 및 스마트 기술을 이용한 디지털 혁신 활동과 연계하여 고객사 생산특성에 적합한 제조혁신체계 구축을 지원합니다."
      />
      <PageHeader
        title={"제조혁신"}
        description={
          "제조혁신 및 스마트 기술을 이용한 디지털 혁신 활동과 연계하여 고객사 생산특성에 적합한 제조혁신체계 구축을 지원합니다."
        }
      />
      <SectionPrograms source={CASES} />
      <SectionWhyUs />
      <SectionContactCta />
    </div>
  )
}
